.wrapper {
  display: flex;
  flex: 1;
  position: relative;
  height: 100%;
}

.videoWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
