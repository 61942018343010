@import-normalize;

@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"), url(./fonts/OpenSans-Regular.ttf) format("truetype");
}

#App {
  background: #333333;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: OpenSans;
}
