.Instructions {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.Instructions.carousel,
.Instructions .carousel__slider,
.Instructions .carousel__slider-tray-wrapper,
.Instructions .carousel__slider-tray,
.Instructions .carousel__slide {
  height: 100%;
}

.Instructions .dots {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 15px 0;
}

.Instructions .carousel__dot {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #aaaaaa;
}

.Instructions .carousel__inner-slide {
  display: flex;
  flex: 1 1;
}

.Instructions .carousel__dot--selected {
  background: #000000;
}

.Instructions .slide {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: #ffffff;
  padding: 50px 10px;
}

.Instructions .slide .text {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
  text-align: center;
}

.Instructions .slide p {
  margin: 9px;
}
