.arContainer {
  width: 100%;
  height: 100%;
}
.resultsElement {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}

.bottomArea {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
}
